import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { CareersService } from '../services/careers.service';
import { AlertService } from "../services/alert.service";
import { ModalService } from "../services/modal.service";
import { CommonService } from "../services/common.service";
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  @ViewChild('ngcareerAddForm', { static: false }) ngcareerAddForm: NgForm;
  careerAddForm: FormGroup;

  constructor(
    private title: Title,
    private careersService: CareersService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private modalService: ModalService,
    private router: Router,
  ) {
    this.title.setTitle('Careers')
  }
  emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  isloggedIn: boolean = false;
  dataLoader: boolean = false;
  user_token = ''

  ngOnInit() {
    this.user_token = this.commonService.getUserData('token');
    this.careerAddForm = this.formBuilder.group({
      full_name: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100)
      ]],
      /* department: [''], */
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern(this.emailRegex)
      ]],
      gender: ['', [Validators.required]],
      mobile_number: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(15)
      ]],
      message: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(1000)
      ]],
      doc: ['', Validators.required],
    });
  }

  onSubmitAddForm() {
    if (this.careerAddForm.valid) {
      let postData = new FormData();
      postData.append("token", this.commonService.getUserData('token'));
      //postData.append("department", this.careerAddForm.value.department);
      postData.append("full_name", this.careerAddForm.value.full_name);
      postData.append("email", this.careerAddForm.value.email);
      postData.append("mobile_number", this.careerAddForm.value.mobile_number);
      postData.append("message", this.careerAddForm.value.message);
      postData.append("file", this.docFilesInput);
      postData.append("gender", this.careerAddForm.value.gender);
      this.dataLoader = true;
      this.careersService.postFormData(postData).subscribe(
        (response: any) => {
          if (response.status) {
            this.alertService.show_alert(response.message);
            this.careerAddForm.reset();
            this.removeFile()
            this.careerAddForm.patchValue({
              gender: ""
            })
          }
          this.dataLoader = false;
        },
        (error) => { this.dataLoader = false; },
        () => { this.dataLoader = false; }
      )
    }
  }

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  docFilesB64Src = '';
  docFilesInput: File = null;

  onFileInput(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 20000000;

      if (fileInput.target.files[0].size > max_size) {
        this.alertService.showValidationErrors("File size should not be greater than 2 MB");
        return false;
      }
      this.careerAddForm.patchValue({
        "doc": "yes"
      })

      this.docFilesInput = fileInput.target.files[0];
      this.docFilesB64Src = fileInput.target.files[0].name;
      this.fileInput.nativeElement.value = '';
    }
  }

  removeFile() {
    this.docFilesB64Src = "";
    this.docFilesInput = null;
    this.careerAddForm.patchValue({
      "doc": ""
    })
  }

}
