import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {
  showRegistrationMessage = false;
  showEmailVerifiedMessage = false;
  action = '';

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.action = params.action;
      if (this.action == 'register') {
        this.showRegistrationMessage = true;
        this.showEmailVerifiedMessage = false;
      } else if (this.action == 'otp') {
        this.showRegistrationMessage = false;
        this.showEmailVerifiedMessage = true;
      }
    })
  }

  ngOnInit() { }

}
