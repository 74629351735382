import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SocialUser } from "angularx-social-login";
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { CommonService } from './common.service'


@Injectable()
export class ApiService {
  apiBaseUrl = '';
  userData = new BehaviorSubject({});
  currentUserData = this.userData.asObservable();

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private commonService: CommonService
  ) {
    this.apiBaseUrl = environment.api_url;
  }

  setUserLoggedIn(ud:any, remember_me:boolean=false) {
    this.commonService.setLoggedInObservable(true);
    this.userData.next(ud);
    this.commonService.setUserData(ud, remember_me);
  }

  setUserLogout() {
    localStorage.removeItem('userData');
    localStorage.removeItem('find_in');
    sessionStorage.removeItem('userData');
    this.commonService.setLoggedInObservable(false);
    this.userData.next({});
    this.router.navigate(['/']);
  }

  sign_up(postData: FormData){
    let apiURL = this.apiBaseUrl + '/account/registration';
    return this.httpClient.post(apiURL, postData).pipe(
      map(
        (response: any) => {
          if (response.status) {
            let userType = response.data.group_name;
            if (userType == 'Patient') {
              this.setUserLoggedIn(response.data);
            } else {
              this.router.navigate(['/thankyou/register']);
            }
          }
          return response;
        }
      )
    );
  }

  login(postData: FormData, remember_me) {
    //postData.append('user_type', 'Patient');
    let apiURL = this.apiBaseUrl + '/account/login';
    return this.httpClient.post(apiURL, postData).pipe(
      map(
        (response: any) => {
          if (response.status) {
            this.setUserLoggedIn(response.data, remember_me);
            if (response.data.group_name != 'Patient') {
              window.location.href = environment.admin_url
            }
          }
          return response;
        }
      )
    );
  }

  social_login(socialUserData: SocialUser, socialPlatform:string) {
    let postData = new FormData();
    postData.append("social_id", socialUserData.id);
    postData.append("social_type", socialPlatform);
    postData.append("user_type", 'Patient');
    postData.append("name", socialUserData.name);
    postData.append("first_name", socialUserData.firstName);
    postData.append("last_name", socialUserData.lastName);
    postData.append("email", socialUserData.email);
    postData.append("picture", socialUserData.photoUrl);
    let apiURL = this.apiBaseUrl + '/account/social_login';
    return this.httpClient.post(apiURL, postData).pipe(
      map(
        (response: any) => {
          if (response.status === true) {
            if (response.data.group_name == 'Patient') {
              this.setUserLoggedIn(response.data);
            }
          }
          return response;
        }
      )
    );
  }

  logout() {
    let apiURL = this.apiBaseUrl + '/account/logout?token=' + this.commonService.getUserData('token');
    this.httpClient.get(apiURL).subscribe();
    this.setUserLogout();
  }

  forget_password_post(postData: FormData) {
    let apiURL = this.apiBaseUrl + '/account/forget_password';
    return this.httpClient.post(apiURL, postData);
  }

  validate_forget_password_opt(code:string) {
    let apiURL = this.apiBaseUrl + '/account/validate_forget_password_opt/' + code;
    return this.httpClient.get(apiURL);
  }

  validate_email_otp_get(code:string, otp:string='') {
    let apiURL = this.apiBaseUrl + '/account/validate_email_otp/'+ code ;
    if(otp) {
      apiURL += '/' + otp;
    }
    return this.httpClient.get(apiURL);
  }

  reset_password_post(postData: FormData) {
    let apiURL = this.apiBaseUrl + '/account/reset_password';
    return this.httpClient.post(apiURL, postData);
  }

  city_get(params: object = {}) {
    let apiURL = this.apiBaseUrl + '/cities?token=' + this.commonService.getUserData('token');
    apiURL += this.commonService.queryParams(params);
    return this.httpClient.get(apiURL);
  }

}
