import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { ApiService } from './services/api.service'
import { CommonService } from './services/common.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {
  userToken='';
  userType='';
  userData:any;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private commonService: CommonService
  ) {
    this.userData = this.commonService.getUserData();
    this.userToken = this.commonService.getUserData('token');
    this.userType = this.commonService.getUserData('group_name');
    let find_in = localStorage.getItem('find_in');
    let remember_me = false;
    if(find_in == 'local') {
      remember_me = true;
    }
    if(this.userToken) {
      this.apiService.setUserLoggedIn(this.userData, remember_me);
    }
  }

  ngOnInit() {
    console.log('this.userType', this.userType);
    if (environment.production && this.userType && this.userType != 'Patient') {
      console.log('redirect to admin');
      window.location.href = environment.admin_url
    }
  }

  ngAfterViewInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }

}
