import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service'

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {
  apiBaseUrl = '';

  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService
  ) {
    this.apiBaseUrl = environment.api_url;
  }

  book_appointment_detail(params: object = {}) {
    let apiURL = this.apiBaseUrl + '/appointments/book_appointment_detail?token=' + this.commonService.getUserData('token');
    apiURL += this.commonService.queryParams(params);
    return this.httpClient.get(apiURL);
  }

  appointment_post(postData: FormData) {
    let apiURL = this.apiBaseUrl + '/appointments';
    return this.httpClient.post(apiURL, postData);
  }

  delete_appointment(appointment_id:number) {
    let apiURL = this.apiBaseUrl + '/appointments/'+appointment_id+'?token=' + this.commonService.getUserData('token');
    return this.httpClient.delete(apiURL);
  }

  appointment_confirmed(postData: FormData) {
    let apiURL = this.apiBaseUrl + '/appointments/confirmed';
    return this.httpClient.post(apiURL, postData);
  }
}
