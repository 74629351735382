import { Component, OnInit, ViewEncapsulation, ViewChild } from "@angular/core";
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialUser, SocialAuthService } from "angularx-social-login";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { DeviceDetectorService } from "ngx-device-detector";

import { ApiService } from "../services/api.service";
import { CommonService } from "../services/common.service";
import { ModalService } from "../services/modal.service";
import { AlertService } from "../services/alert.service";
declare var jQuery: any;
import countyCode from 'src/assets/countryPhoneCodes.json';
import { concat } from "rxjs";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class LoginComponent implements OnInit {
  isloggedIn = false;
  userData: any = {};

  @ViewChild('ngloginform', { static: false }) ngloginform: NgForm;
  loginForm: FormGroup;
  loginFormLoader = false;

  @ViewChild('ngregisterform', { static: false }) ngregisterform: NgForm;
  registerForm: FormGroup;
  registerFormLoader = false;
  emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  action = 'login';
  type = 'seeker';
  user_type = 'Patient';
  returnUrl = '';

  firebase_token = '';
  deviceInfo = {
    browser: "",
    browser_version: "",
    device: "",
    os: "",
    os_version: "",
    userAgent: ""
  };
  public countries:any = countyCode;

  constructor(
    private title: Title,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private socialAuthService: SocialAuthService,
    private angularFireMessaging: AngularFireMessaging,
    private deviceDetectorService: DeviceDetectorService,
    private modalService: ModalService,
    private apiService: ApiService,
    private commonService: CommonService,
    private alertService: AlertService
  ) {
    this.title.setTitle('Login');
    this.loginForm = this.fb.group({
      email_mobile: ["", [
          Validators.required,
          Validators.email,
          Validators.pattern(this.emailRegex)
        ]
      ],
      password: ["", [
          Validators.required
        ]
      ],
      remember_me: [""]
    });

    this.registerForm = this.fb.group({
      full_name: ['', [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50)
        ]
      ],
      email: ['', [
          Validators.required,
          Validators.email,
          Validators.pattern(this.emailRegex)
        ]
      ],
      county_Code:['',[
       
      ]],
      mobile_number: ['', [
          Validators.required,
          //Validators.minLength(10),
          Validators.maxLength(20)
        ]
      ],
      password: ['', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(15)
        ]
      ],
      accept_tnc: ['', [
          Validators.requiredTrue
        ]
      ]
    });
    this.modalService.close_login_modal();
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.type = params.get('type');
      if (this.type == 'hospital' || this.type == 'clinic') {
        this.user_type = 'Clinic';
      } else if (this.type == 'carer') {
        this.user_type = 'Doctor';
      } else {
        this.type = 'seeker';
      }

      this.action = params.get('action');
      this.returnUrl = params.get('returnUrl');
    });
    this.commonService.isloggedInObservable.subscribe(
      (resp: any) => {
        this.isloggedIn = resp;
        if (this.isloggedIn && this.returnUrl == null) {
          if(this.returnUrl) {
            window.location.href = this.returnUrl;
          } else {
            this.router.navigate(['/']);
          }
        }
      }
    );
  }

  ngOnInit() {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.firebase_token = token;
      }
    );
  }

  ngAfterViewInit() {
    if (this.action == 'register') {
      jQuery('.register-info-box').fadeOut();
      jQuery('.login-info-box').fadeIn();
      jQuery('.white-panel').addClass('right-log');
      jQuery('.register-show').addClass('show-log-panel');
      jQuery('.login-show').removeClass('show-log-panel');
    } else {
      jQuery('.register-info-box').fadeIn();
      jQuery('.login-info-box').fadeOut();
      jQuery('.white-panel').removeClass('right-log');
      jQuery('.login-show').addClass('show-log-panel');
      jQuery('.register-show').removeClass('show-log-panel');
    }
  }

  onSubmitLogin() {
    if (this.loginForm.valid) {
      let postData = new FormData();
      postData.append('email', this.loginForm.value.email_mobile);
      postData.append('password', this.loginForm.value.password);
      postData.append('user_type', this.user_type);
      postData.append("deviceInfo[systemName]", 'Website');
      postData.append("deviceInfo[token]", this.firebase_token);
      postData.append("deviceInfo[deviceUniqueID]", this.firebase_token);
      postData.append("deviceInfo[deviceId]", this.deviceInfo.browser);
      postData.append("deviceInfo[deviceDesignName]", this.deviceInfo.browser);
      postData.append("deviceInfo[brandName]", this.deviceInfo.browser);
      postData.append("deviceInfo[deviceName]", this.deviceInfo.browser);
      postData.append("deviceInfo[manufacturer]", this.deviceInfo.os);
      postData.append("deviceInfo[systemVersion]", this.deviceInfo.browser_version);
      postData.append("deviceInfo[systemApiLevel]", this.deviceInfo.browser_version);
      postData.append("deviceInfo[appVersion]", this.deviceInfo.browser_version);
      postData.append("deviceInfo[deviceModel]", this.deviceInfo.browser);
      postData.append("deviceInfo[uiMode]", this.deviceInfo.os_version);
      postData.append("deviceInfo[Fingerprint]", this.deviceInfo.userAgent);
      postData.append("deviceInfo[Serial]", this.deviceInfo.browser_version);

      this.loginFormLoader = true;
      this.apiService.login(postData, this.loginForm.value.remember_me).subscribe(
        (response: any) => {
          this.loginFormLoader = false;
          if (response.status && this.returnUrl) {
            console.log('this.returnUrl', this.returnUrl);
            //this.commonService.redirectTo(this.returnUrl);
            //this.router.navigateByUrl(this.returnUrl);
            window.location.href = this.returnUrl;
          }
        },
        (error) => { this.loginFormLoader = false; }
      );
    }
  }

  onSubmitRegister() {
    if (this.registerForm.valid) {
      let postData = new FormData();
      postData.append('full_name', this.registerForm.value.full_name);
      postData.append('mobile_number',this.registerForm.value.county_Code + this.registerForm.value.mobile_number);
      postData.append('email', this.registerForm.value.email);
      postData.append('password', this.registerForm.value.password);
      postData.append('user_type', this.user_type);
      postData.append("deviceInfo[systemName]", 'Website');
      postData.append("deviceInfo[token]", this.firebase_token);
      postData.append("deviceInfo[deviceUniqueID]", this.firebase_token);
      postData.append("deviceInfo[deviceId]", this.deviceInfo.browser);
      postData.append("deviceInfo[deviceDesignName]", this.deviceInfo.browser);
      postData.append("deviceInfo[brandName]", this.deviceInfo.browser);
      postData.append("deviceInfo[deviceName]", this.deviceInfo.browser);
      postData.append("deviceInfo[manufacturer]", this.deviceInfo.os);
      postData.append("deviceInfo[systemVersion]", this.deviceInfo.browser_version);
      postData.append("deviceInfo[systemApiLevel]", this.deviceInfo.browser_version);
      postData.append("deviceInfo[appVersion]", this.deviceInfo.browser_version);
      postData.append("deviceInfo[deviceModel]", this.deviceInfo.browser);
      postData.append("deviceInfo[uiMode]", this.deviceInfo.os_version);
      postData.append("deviceInfo[Fingerprint]", this.deviceInfo.userAgent);
      postData.append("deviceInfo[Serial]", this.deviceInfo.browser_version);
      this.registerFormLoader = true;
      this.apiService.sign_up(postData).subscribe(
        (response: any) => {
          if (response.status) {
            this.registerForm.reset();
            if(this.returnUrl) {
              console.log('this.returnUrl', this.returnUrl);
              //this.commonService.redirectTo(this.returnUrl);
              //this.router.navigateByUrl(this.returnUrl);
              window.location.href = this.returnUrl;
            }
          }
          this.registerFormLoader = false;
        },
        (error) => { this.registerFormLoader = false; }
      )
    }
  }

  closeLoginModal() {
    this.modalService.close_login_modal();
  }

  public socialSignIn(socialPlatform : string) {
    let socialPlatformProvider:any;
    let loginOption:any;
    if (socialPlatform == "Facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
      loginOption = {
        scope: 'email',
        return_scopes: true
      }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11
    } else {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
      const loginOption = {
        scope: 'profile email'
      }; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig
    }

    this.loginFormLoader = true;
    this.socialAuthService.signIn(socialPlatformProvider, ).then(
      (socialUserData: SocialUser) => {
        console.log(socialUserData);
        const email = socialUserData.email;
        const name = socialUserData.name;
        if(email === undefined) {
          this.alertService.showValidationErrors('Must provide an email');
          this.socialAuthService.signOut();
          return false;
        }
        if(name === undefined) {
          this.alertService.showValidationErrors('Must provide a name');
          this.socialAuthService.signOut();
          return false;
        }

        this.apiService.social_login(socialUserData, socialPlatform).subscribe(
          (response: any) => {
            if (!response.status) {
              this.alertService.show_alert(response.message, '', 'error');
              this.loginFormLoader = false;
            }
          },
          (error) => { this.loginFormLoader = false; },
          () => { this.loginFormLoader = false; }
        );
      }
    ).catch(
      (error:any) => {
        this.loginFormLoader = false;
        console.error('error', error);
        //this.alertService.showValidationErrors('There is some error while social login, Please try later');
      }
    );

    /*
    Facebook login data
      authToken: "EAACDyfXlRpoBAMd9VeyZAWUZB3rbZBL3gdRpdfZCMwGP8UxmRs2vQHQvm9GT5XRQMifB4R030amWmZBQuJBuRZCkIZA6G8svKnmBL7hK9GzhgomLCtSAPPcNFt52ukYZBVE9fKt9IWFDd0scTiOsXZAyBug1qaXzywrlKOgxQYztqaOhElSuE2Ci5lHELTJtGkZAEOsrPzwubX8AZDZD"
      email: undefined
      firstName: undefined
      id: undefined
      lastName: undefined
      name: undefined
      photoUrl: "https://graph.facebook.com/undefined/picture?type=normal"
      provider: "FACEBOOK"
    */

    /*
      Google login data
      authToken: "ya29.a0AfH6SMASe7C6vPKA6RMKLP3BgGRD51tw_hXPTgI0iPzVjdLfChj1LOGX27AfTsyGPXMGPT2eQG31mNZBeFTBgqnNvLM9G0hKdlR558PRA3p-sUK__q0YlixBmI6QxF9McU-it3KsZQ1gJ5W7HM6Ow2GlZLu6_bNFHF5Xsj8gCZc"
      email: "manish.svim2011@gmail.com"
      firstName: "manish"
      id: "101099640809879160228"
      idToken: "eyJhbGciOiJSUzI1NiIsImtpZCI6ImUxOTdiZjJlODdiZDE5MDU1NzVmOWI2ZTVlYjQyNmVkYTVkNTc0ZTMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNTEzNTM5MzU1MTMyLWJsYjJmcDNmdXJzZW5kZms3dmxzY2FvaXN1OTc2MTdqLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNTEzNTM5MzU1MTMyLWJsYjJmcDNmdXJzZW5kZms3dmxzY2FvaXN1OTc2MTdqLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTAxMDk5NjQwODA5ODc5MTYwMjI4IiwiZW1haWwiOiJtYW5pc2guc3ZpbTIwMTFAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiJWX293WVFxdjUycUdBeWVPRy15bkhRIiwibmFtZSI6Im1hbmlzaCB5YWRhdiIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS0vQU9oMTRHaDRIUFNnWFFCVWNTZHF3b1FEX081bjlnRTFjYXVKMlZhRFp4M3FZQT1zOTYtYyIsImdpdmVuX25hbWUiOiJtYW5pc2giLCJmYW1pbHlfbmFtZSI6InlhZGF2IiwibG9jYWxlIjoiZW4tR0IiLCJpYXQiOjE2MDc5NDAyNTMsImV4cCI6MTYwNzk0Mzg1MywianRpIjoiZGYyYjBiYzYwODEzZmZmOGFhZDM5NzhlYjZiN2I4NjhkNDdjMzU1YSJ9.Xacyj0HEBvJMcoVOsQ6zs_KBkfcWQMazrgDZI2zhS7D5D3Wyp_cQxxbJ7lg5xG5bmxN6TdqLwvOvuMt7MyXwMyPT3mqgIJ4iciFnWoWRhTNEM15aeFuoSLFA6QZxjaStmREEeT3Qhz06ZTdD0vG0b3xCChQR5dSwzxyegWZXuwFpDqtejz3rGx5aOX2hr6Db0uuUYH1Qpl4msR6KXa291b_x8UGOTQjH0Sho4ndK2W-AHaycESuh92UPA7EKQoKVVRqTDfUsYKf-gKhK5FPzBWSSNpYP_djz11Yljhbag0fBGVhRN7A-Z_CDFiVaog8MJYC89Ftlrh7FRY9E63tLWw"
      lastName: "yadav"
      name: "manish yadav"
      photoUrl: "https://lh3.googleusercontent.com/a-/AOh14Gh4HPSgXQBUcSdqwoQD_O5n9gE1cauJ2VaDZx3qYA=s96-c"
      provider: "GOOGLE
    */
  }

}
