import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { NgxPaginationModule } from 'ngx-pagination';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgSelect2Module } from 'ng-select2';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { NgxContentLoadingModule } from "ngx-content-loading"; //working properly

import { AuthGuard } from './services/auth.guard';
import { ApiService } from './services/api.service';
import { ServiceInterceptor } from './services/service.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FrontendLayoutComponent } from './_layout/frontend/frontend-layout/frontend-layout.component';
import { FrontendHeaderComponent } from './_layout/frontend/frontend-header/frontend-header.component';
import { FrontendFooterComponent } from './_layout/frontend/frontend-footer/frontend-footer.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { ProvidersComponent } from './providers/providers.component';
import { BookAppointmentComponent } from './book-appointment/book-appointment.component';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './services/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '@angular/common';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CareersComponent } from './careers/careers.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { PageComponent } from './page/page.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { JoinMedzigoComponent } from './_shared/join-medzigo/join-medzigo.component';
import { AboutUsComponent } from './about-us/about-us.component';


/* const fbLoginOptions = {
  scope: 'email',
  return_scopes: true,
  enable_profile_selector: true,
  version: 'v2.11'
}; */

@NgModule({
  declarations: [
    AppComponent,

    FrontendLayoutComponent,
    FrontendHeaderComponent,
    FrontendFooterComponent,

    HomeComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    ProvidersComponent,
    BookAppointmentComponent,
    ThankyouComponent,
    VerifyOtpComponent,
    CareersComponent,
    ContactUsComponent,
    PageComponent,
    NotfoundComponent,
    JoinMedzigoComponent,
    AboutUsComponent,
  ],
  imports: [
    NgSelect2Module,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    SweetAlert2Module.forRoot(),
    BrowserAnimationsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    DeviceDetectorModule.forRoot(),
    ContentLoaderModule,
    NgxPaginationModule,
    SwiperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    SocialLoginModule,
    NgxContentLoadingModule
  ],
  providers: [
    AuthGuard,
    ApiService,
    MessagingService,
    AsyncPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServiceInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleLoginProiverId)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppID)
          }
        ]
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
