import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from "../services/common.service";
import { ApiService } from "../services/api.service";

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VerifyOtpComponent implements OnInit {
  isloggedIn: boolean = false;
  encrypted_code = '';

  otpForm: FormGroup;
  otpFormLoader: boolean = false;

  constructor(
    private title: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private commonService: CommonService
  ) {
    this.title.setTitle('Verify Otp');
    this.commonService.isloggedInObservable.subscribe(
      (resp: any) => {
        this.isloggedIn = resp;
        if (this.isloggedIn) {
          this.commonService.userDefaultRoute();
        } else {
          this.activatedRoute.params.subscribe(routeParams => {
            this.encrypted_code = routeParams.code;
            if (this.encrypted_code) {
              this.otpFormLoader = true;
              this.apiService.validate_email_otp_get(this.encrypted_code).subscribe(
                (response: any) => {
                  if (!response.status) {
                    this.commonService.userDefaultRoute();
                  }
                  this.otpFormLoader = false;
                },
                (error:any) => { this.otpFormLoader = false; }
              );
            } else {
              this.commonService.userDefaultRoute();
            }
          });
        }
      }
    );
  }

  ngOnInit() {
    this.otpForm = this.formBuilder.group({
      otp: ["", [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
      ]]
    });
  }

  onSubmitOtpForm() {
    if (this.otpForm.valid) {
      this.otpFormLoader = true;
      this.apiService.validate_email_otp_get(this.encrypted_code, this.otpForm.value.otp).subscribe(
        (response: any) => {
          if (response.status) {
            this.router.navigate(['/thankyou/otp']);
          }
          this.otpFormLoader = false;
        },
        (error:any) => { this.otpFormLoader = false; }
      );
    }
  }

}
