import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

import { ArticlesService } from '../services/articles.service';
import { CmsService } from '../services/cms.service';
import { iArticle } from '../services/interface/i-article';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PageComponent implements OnInit {
  
  showContentLoader = true;
  alias = ''
  articleDetail:iArticle;
  site_url = environment.site_url;

  content = {
    page_footer_content: ""
  };

  constructor(
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private articlesService: ArticlesService,
    private cmsService: CmsService
  ) {
    this.activatedRoute.params.subscribe(routeParams => {
      this.alias = routeParams.alias;
    });
  }

  ngOnInit() {
    this.article_detail();
    this.cmsService.getContent().subscribe(
      (response: any) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].section_type == "page_footer_content") {
            this.content.page_footer_content = response.data[i].content;
          }
        }
      }
    )
  }

  article_detail() {
    this.articlesService.get(this.alias).subscribe(
      (response: any) => {
        if(response.status){
          this.articleDetail = response.data;
          this.title.setTitle(this.articleDetail.title);
          this.showContentLoader = false;
        } else {
          this.router.navigate(['/']);
        }
      }
    )
  }

}
