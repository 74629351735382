import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from "../services/common.service";
import { PasswordValidation } from '../services/password-validation';
import { ApiService } from "../services/api.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {
  isloggedIn: boolean = false;
  message='';
  isPasswordReset:boolean = false;

  resetPasswordForm: FormGroup;
  resetPasswordFormLoader: boolean = false;

  constructor(
    private title: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private apiService: ApiService,
    private commonService: CommonService,
  ) {
    this.title.setTitle('Reset Password');
    this.commonService.isloggedInObservable.subscribe(
      (resp:any) => {
        this.isloggedIn = resp;
        if (this.isloggedIn) {
          this.router.navigate(['/']);
        } else {
          this.activatedRoute.params.subscribe(routeParams => {
            let code = routeParams.code;
            this.apiService.validate_forget_password_opt(code).subscribe(
              (response:any) => {
                if(!response.status) {
                  this.commonService.userDefaultRoute();
                }
              });
          });
        }
      }
    );
  }

  ngOnInit() {
    this.resetPasswordForm = this.fb.group({
      otp: ["", [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
      ]],
      password: ["", [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(15)
      ]],
      confirm_password: ["", [
        Validators.required
      ]]
    }, {
      validator: PasswordValidation.MatchPassword
    });
  }

  onSubmitResetPasswordForm() {
    if(this.resetPasswordForm.valid) {
      let postData = new FormData();
      postData.append('otp', this.resetPasswordForm.value.otp);
      postData.append('password', this.resetPasswordForm.value.password);
      postData.append('confirm_password', this.resetPasswordForm.value.confirm_password);
      this.resetPasswordFormLoader = true;
      this.apiService.reset_password_post(postData).subscribe(
        (response:any)=>{
          if(response.status) {
            this.message = response.message;
            this.isPasswordReset = true;
          }
          this.resetPasswordFormLoader = false;
        },
        (error)=>{ this.resetPasswordFormLoader = false; },
        ()=>{ this.resetPasswordFormLoader =false; }
      )
    }
  }

}
