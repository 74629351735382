import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CmsService } from '../services/cms.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AboutUsComponent implements OnInit {

  content = {
    about_us_content: ""
  };

  constructor(
    private title: Title,
    private cmsService: CmsService
  )
  {
    this.title.setTitle('Medzigo: About Us');
  }

  ngOnInit() {
    this.cmsService.getContent().subscribe(
      (response: any) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].section_type == "about_us_content") {
            this.content.about_us_content = response.data[i].content;
          }
        }
      }
    )
  }

}
