import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { HomeComponent } from './home/home.component';
import { FrontendLayoutComponent } from './_layout/frontend/frontend-layout/frontend-layout.component';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ProvidersComponent } from './providers/providers.component';
import { BookAppointmentComponent } from './book-appointment/book-appointment.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { CareersComponent } from './careers/careers.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PageComponent } from './page/page.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { AboutUsComponent } from './about-us/about-us.component';

const routes: Routes = [
  //Frontend routes goes here
  {
    path: '',
    component: FrontendLayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'home', component: HomeComponent },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: LoginComponent },
      { path: 'forget-password', component: ForgetPasswordComponent },
      { path: 'reset-password/:code', component: ResetPasswordComponent },
      { path: 'thankyou/:action', component: ThankyouComponent },
      { path: 'providers', component: ProvidersComponent },
      { path: 'verify-otp/:code', component: VerifyOtpComponent },
      { path: 'about', component: AboutUsComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'career', component: CareersComponent },
      { path: 'careers', component: CareersComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'contact', component: ContactUsComponent },
      { path: 'page/:alias', component: PageComponent },
      { path: 'book-appointment', component: BookAppointmentComponent, canActivate: [AuthGuard] },
      { path: 'doctors', loadChildren: () => import('./doctors/doctors.module').then(m => m.DoctorsModule) },
      { path: 'consult', loadChildren: () => import('./consult/consult.module').then(m => m.ConsultModule) },
      { path: 'healthfeed', loadChildren: () => import('./healthfeed/healthfeed.module').then(m => m.HealthfeedModule) },
      { path: 'healthfeeds', loadChildren: () => import('./healthfeed/healthfeed.module').then(m => m.HealthfeedModule) },
      
      // This must be the last route
      { path: '404', component: NotfoundComponent },
      { path: '**', redirectTo: '/404' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
