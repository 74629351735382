import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  loggedInObservable = new BehaviorSubject<boolean>(false);

  constructor(private router: Router) { }

  setLoggedInObservable(obValue:boolean){
    this.loggedInObservable.next(obValue);
  }

  get isloggedInObservable() {
    return this.loggedInObservable.asObservable();
  }

  isLoggedIn() {
    return this.getUserData('token') ? true : false;
  }

  setUserData(userData:any, storage_type:boolean=false) {
    localStorage.setItem('find_in', 'local');
    localStorage.setItem('userData', JSON.stringify(userData));
    /*
    if(storage_type) {
      localStorage.setItem('find_in', 'local');
      localStorage.setItem('userData', JSON.stringify(userData));
    } else {
      localStorage.setItem('find_in', 'session');
      sessionStorage.setItem('userData', JSON.stringify(userData));
    }*/
  }

  getUserData(dataKey:string = '') {
    const find_in = localStorage.getItem('find_in');
    let userData = '';
    if(find_in == 'local')
      userData = (localStorage.getItem('userData')) ? localStorage.getItem('userData') : '';
    else
      userData = (sessionStorage.getItem('userData')) ? sessionStorage.getItem('userData') : '';

    if(dataKey != '') {
      if(userData != '') {
        let parsedUserData = JSON.parse(userData);
        return parsedUserData[dataKey];
      }
      return '';
    }
    return (userData != '') ? JSON.parse(userData) : '';
  }

  userDefaultRoute() {
    let userGroup = this.getUserData('group_name');
    if(userGroup == "Patient") {
      this.router.navigate(['/']);
    }
    this.router.navigate(['/']);
  }

  queryParams(params:object) {
    let queryParams = '';
    for (const property in params) {
      queryParams += '&'+property + '=' + params[property];
    }
    return queryParams;
  }

  days() {
    let daysArray = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return daysArray;
  }

  years() {
    let d = new Date();
    let n = d.getFullYear();
    let yearsArray = [];
    yearsArray.push(n);
    for(let i=1; i<=100; i++) {
      yearsArray.push(n-i);
    }
    return yearsArray;
  }

  dateFormat(dateTimeString:Date) {
    if(dateTimeString) {
      let startDate = new Date(dateTimeString); //Sun Apr 05 2020 00:00:00 GMT+0530 (India Standard Time)
      let startYear = startDate.getFullYear(), startMonth = '' + (startDate.getMonth() + 1), startDay = '' + startDate.getDate();
      if (startMonth.length < 2) startMonth = '0' + startMonth;
      if (startDay.length < 2) startDay = '0' + startDay;
      return startYear +'-'+startMonth +'-'+startDay;
    }
    return '';
  }

  currentTimeAMPM(date:any) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  roundToLowestTen(i:number) {
    if(i > 10) {
      return parseInt((i / 10)+'', 10) * 10;
    }
    return i;
  }

  checkMenusUrlType(menus=[]) {
    if (menus.length == 0) {
      return menus;
    }
    for (let k = 0; k < menus.length; k++) {
      var domain = menus[k].route.split('/');
      if (domain[0] == 'https:' || domain[0] == 'http:') {
        menus[k]['type'] = "url";
      } else {
        menus[k]['type'] = "route";
      }
    }
    return menus;
  }

}
