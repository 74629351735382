import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CareersService {
  apiBaseUrl = '';
  constructor(
    private httpClient: HttpClient
  ) {
    this.apiBaseUrl = environment.api_url;
  }

  postFormData(postData: FormData) {
    let apiURL = this.apiBaseUrl + '/careers';
    return this.httpClient.post(apiURL, postData);
  }
}
