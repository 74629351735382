import { Component, OnInit, ViewEncapsulation, } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { CommonService } from "../services/common.service";
import { ApiService } from "../services/api.service";
import { AlertService } from "../services/alert.service";

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ForgetPasswordComponent implements OnInit {
  isloggedIn: boolean = false;

  forgetPasswordForm: FormGroup;
  forgetPasswordFormSubmitted: boolean = false;
  emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  message:string='';

  constructor(
    private title: Title,
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService,
    private commonService: CommonService,
    private alertService: AlertService,
  ) {
    this.title.setTitle('Forget Password');
    this.commonService.isloggedInObservable.subscribe(
      (resp:any) => {
        this.isloggedIn = resp;
        if(this.isloggedIn)
          this.router.navigate(['/']);
      }
    );

    this.forgetPasswordForm = this.fb.group({
      email: ["", [
          Validators.required,
          Validators.email,
          Validators.pattern(this.emailRegex)
        ]
      ]
    });
  }

  ngOnInit() { }

  onSubmitForgetPasswordForm() {
    if(this.forgetPasswordForm.valid) {
      let postData = new FormData();
      postData.append('email', this.forgetPasswordForm.value.email);
      this.forgetPasswordFormSubmitted = true;
      this.apiService.forget_password_post(postData).subscribe(
        (response:any)=>{
          if(response.status) {
            this.message = response.message;
          }
          this.forgetPasswordFormSubmitted = false;
        },
        (error)=>{ this.forgetPasswordFormSubmitted = false; },
        ()=>{ this.forgetPasswordFormSubmitted =false; }
      )
    }
  }

}
