import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service'

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  apiBaseUrl = '';

  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService
  ) {
    this.apiBaseUrl = environment.api_url;
  }

  getFAQ(params: object = {}) {
    let apiURL = this.apiBaseUrl + '/faq?t=t';
    apiURL += this.commonService.queryParams(params);
    return this.httpClient.get(apiURL);
  }

  getContent(id='', params: object = {}) {
    let apiURL = this.apiBaseUrl + '/cms/'+ id +'?t=t';
    apiURL += this.commonService.queryParams(params);
    return this.httpClient.get(apiURL);
  }

  getSliders(params: object = {}) {
    let apiURL = this.apiBaseUrl + '/cms_slider?t=t';
    apiURL += this.commonService.queryParams(params);
    return this.httpClient.get(apiURL);
  }

  getMenus(id='', params:object={}) {
    let apiURL = this.apiBaseUrl + '/cms_menu/'+id+'?token=' + this.commonService.getUserData('token');
    apiURL += this.commonService.queryParams(params);
    console.log(apiURL);
    
    return this.httpClient.get(apiURL);
  }

}
