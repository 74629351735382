import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CmsService } from '../../services/cms.service';

@Component({
  selector: 'app-join-medzigo',
  templateUrl: './join-medzigo.component.html',
  styleUrls: ['./join-medzigo.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class JoinMedzigoComponent implements OnInit {

  content = {
    join_medzigo_content: ""
  };

  constructor(
    private cmsService: CmsService
  ) { }

  ngOnInit() {
    this.cmsService.getContent().subscribe(
      (response: any) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].section_type == "join_medzigo_content") {
            this.content.join_medzigo_content = response.data[i].content;
          }
        }
      }
    )
  }

}
