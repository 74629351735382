import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SwiperOptions } from "swiper";

import { CommonService } from '../services/common.service';
import { CmsService } from '../services/cms.service';
import { SpecializationService } from '../services/specialization.service';
import { ArticlesService } from '../services/articles.service';
import { FeedbacksService } from '../services/feedbacks.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  isloggedIn: boolean = false;
  articles = [];
  feedbacks = [];

  showSpecialityReactiveLoader = true;
  specialities = []
  public specialitySlider: SwiperOptions = {
    slidesPerView: 6,
    spaceBetween: 30,
    navigation: true,
    breakpoints: {
      360: {
        slidesPerView: 3,
        spaceBetween: 5,
      },
      640: {
        slidesPerView: 6,
        spaceBetween: 30,
      }
    }
  };

  cms_sliders_patient = [];
  cms_sliders_doctor = [];
  content = {
    banner_content: "",
    banner_image: "",
    specialty_content: "",
    find_doctor_image: "",
    online_doctor_image: "",
    app_features_content: "",
    read_top_news_content: "",
    download_app_content: "",
    download_app_image: "",
    join_medzigo_content: "",
  };
  public patientSliderOptions: SwiperOptions = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    loop: true,
    slideToClickedSlide: true,
    coverflowEffect: {
      rotate: 0,
      stretch: 185,
      depth: 44,
      modifier: 3,
      slideShadows: false,
    },
    navigation: {
      nextEl: '.homePage_appfeature .swiper-button-next',
      prevEl: '.homePage_appfeature .swiper-button-prev',
    },
    breakpoints: {
      1399: {
        coverflowEffect: {
          rotate: 1,
          stretch: 187,
          depth: 60,
          modifier: 3,

        },
      },
      1199: {
        coverflowEffect: {
          rotate: 1,
          stretch: 144,
          depth: 25,
          modifier: 3,

        },
      },
      992: {
        coverflowEffect: {
          rotate: 1,
          stretch: 37,
          depth: 29,
          modifier: 3,
        },
      },
      767: {
        effect: 'slide',
        coverflowEffect: {
          rotate: 1,
          stretch: 37,
          depth: 29,
          modifier: 3,
        },
      },
    }
  };
  public doctorSliderOptions: SwiperOptions = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    loop: true,
    slideToClickedSlide: true,
    coverflowEffect: {
      rotate: 0,
      stretch: 185,
      depth: 44,
      modifier: 3,
      slideShadows: false,
    },
    navigation: {
      nextEl: '.homePage_appfeature .swiper-button-next-slider02',
      prevEl: '.homePage_appfeature .swiper-button-prev-slider02',
    },
    breakpoints: {
      1399: {
        coverflowEffect: {
          rotate: 1,
          stretch: 187,
          depth: 60,
          modifier: 3,

        },
      },
      1199: {
        coverflowEffect: {
          rotate: 1,
          stretch: 144,
          depth: 25,
          modifier: 3,

        },
      },
      992: {
        coverflowEffect: {
          rotate: 1,
          stretch: 37,
          depth: 29,
          modifier: 3,
        },
      },
      767: {
        effect: 'slide',
        coverflowEffect: {
          rotate: 1,
          stretch: 37,
          depth: 29,
          modifier: 3,
        },
      },
    }
  };
  public newsSliderOptions: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.homePage_news .swiper-button-next',
      prevEl: '.homePage_news .swiper-button-prev',
    },
    breakpoints: {
      360: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 30,
      }
    }
  };
  public testimonialSlider: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
      nextEl: '.homePage_testimonial .swiper-button-next',
      prevEl: '.homePage_testimonial .swiper-button-prev',
    },
    breakpoints: {
      360: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 30,
      }
    }
  };
  constructor(
    private title: Title,
    private commonService: CommonService,
    private feedbacksService: FeedbacksService,
    private specializationService: SpecializationService,
    private articlesService: ArticlesService,
    private cmsService: CmsService,
  ) {
    this.title.setTitle('Mentorly Plus: Home');
    this.commonService.isloggedInObservable.subscribe(
      (resp: any) => {
        this.isloggedIn = resp;
      }
    );
  }

  ngOnInit() {
    this.getPageContent();
    this.getSpecialities();
  }

  getPageContent() {
    this.cmsService.getContent().subscribe(
      (response: any) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].section_type == "banner_image") {
            this.content.banner_image = response.data[i].content;
          }

          if (response.data[i].section_type == "banner_content") {
            this.content.banner_content = response.data[i].content;
          }

          if (response.data[i].section_type == "specialty_content") {
            this.content.specialty_content = response.data[i].content;
          }

          if (response.data[i].section_type == "find_doctor_image") {
            this.content.find_doctor_image = response.data[i].content;
          }

          if (response.data[i].section_type == "online_doctor_image") {
            this.content.online_doctor_image = response.data[i].content;
          }

          if (response.data[i].section_type == "app_features_content") {
            this.content.app_features_content = response.data[i].content;
          }

          if (response.data[i].section_type == "read_top_news_content") {
            this.content.read_top_news_content = response.data[i].content;
          }

          if (response.data[i].section_type == "download_app_content") {
            this.content.download_app_content = response.data[i].content;
          }

          if (response.data[i].section_type == "download_app_image") {
            this.content.download_app_image = response.data[i].content;
          }

          if (response.data[i].section_type == "join_medzigo_content") {
            this.content.join_medzigo_content = response.data[i].content;
          }
        }
      }
    )

    this.articlesService.get('', {limit:5}).subscribe(
      (response: any) => {
        if (response.status) {
          this.articles = response.data;
        }
      }
    )

    this.feedbacksService.feedback_front({limit:10}).subscribe(
      (response: any) => {
        if (response.status) {
          this.feedbacks = response.data;
        }
      }
    )

    this.cmsService.getSliders({slider_for:'Patient', pagination:'No'}).subscribe(
      (response: any) => {
        if (response.status) {
          this.cms_sliders_patient = response.data;
        }
      }
    )

    this.cmsService.getSliders({slider_for:'Doctor', pagination:'No'}).subscribe(
      (response: any) => {
        if (response.status) {
          this.cms_sliders_doctor = response.data;
        }
      }
    )
  }

  getSpecialities() {
    this.showSpecialityReactiveLoader = true;
    this.specializationService.get({parent_id: 0, limit: 8}).subscribe(
      (response: any) => {
        if (response.status) {
          this.specialities = response.data;
        }
        this.showSpecialityReactiveLoader = false;
      },
      (error) => { this.showSpecialityReactiveLoader = false; },
      () => { this.showSpecialityReactiveLoader = false; }
    )
  }

}
