import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, NgForm, FormGroupDirective } from '@angular/forms';

import { ContactUsService } from '../services/contact-us.service';
import { CmsService } from '../services/cms.service';
import { AlertService } from "../services/alert.service";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  
  @ViewChild('ngContactUsAddForm', { static: false }) ngContactUsAddForm: NgForm;
  contactUsAddForm: FormGroup;
  emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  dataLoader: boolean = false;
  user_token = ''

  interestedInSelectBoxOptions:string[] = [];

  constructor(
    private title: Title,
    private cmsService: CmsService,
    private contactUsService: ContactUsService,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) {
    this.title.setTitle('Contact Us')
  }

  content = {
    address: '',
    contact_number: '',
    email: ''
  }

  ngOnInit() {
    this.contactUsService.getInterestedInSelectBoxOptions().subscribe((options:string[]) => {
      this.interestedInSelectBoxOptions = options}
    );

    this.cmsService.getContent().subscribe(
      (response: any) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].section_type == "address") {
            this.content.address = response.data[i].content;
          }

          if (response.data[i].section_type == "contact_number") {
            this.content.contact_number = response.data[i].content;
          }

          if (response.data[i].section_type == "email") {
            this.content.email = response.data[i].content;
          }
        }
      }
    )

    this.contactUsAddForm = this.formBuilder.group({
      interested_in: ['', Validators.required],
      user_type: ['', Validators.required],
      full_name: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100)
      ]],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern(this.emailRegex)
      ]],
      mobile_number: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(15)
      ]],
      message: ['', [
        Validators.maxLength(1000)
      ]],
      country: ['', Validators.required],
      city: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100)]],
    });

  }
  

  onSubmitAddForm(formDirective: FormGroupDirective) {
    if (this.contactUsAddForm.valid) {
      this.dataLoader = true;
      let postData = new FormData();
      postData.append("interested_in", this.contactUsAddForm.value.interested_in);
      postData.append("user_type", this.contactUsAddForm.value.user_type);
      postData.append("full_name", this.contactUsAddForm.value.full_name);
      postData.append("email", this.contactUsAddForm.value.email);
      postData.append("mobile_number", this.contactUsAddForm.value.mobile_number);
      postData.append("message", this.contactUsAddForm.value.message);
      postData.append("country", this.contactUsAddForm.value.country);
      postData.append("city", this.contactUsAddForm.value.city);
      this.contactUsService.postFormData(postData).subscribe(
        (response: any) => {
          if (response.status) {
            this.alertService.show_alert(response.message);
            this.contactUsAddForm.patchValue({
              "interested_in": "",
              "user_type": "",
              "country": ""
            });
          
           formDirective.resetForm();
           this.contactUsAddForm.reset();
          }
          this.dataLoader = false;
        },
        (error) => { this.dataLoader = false; },
        () => { this.dataLoader = false; }
      )
    }
  }
}
