import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CmsService } from '../services/cms.service';
import { CommonService } from '../services/common.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProvidersComponent implements OnInit {

  constructor(
    private cmsService: CmsService,
    private commonService: CommonService,
    private title: Title,
  ) {
    this.title.setTitle('Consult');
    this.commonService.isloggedInObservable.subscribe(
      (resp: any) => {
        this.isloggedIn = resp;
      }
    );
  }

  content = {
    provider_banner_content: "",
    provider_banner_image: "",
    provider_banner_app: "",
    provider_users_detail: "",
    provider_what_we_do: "",
    provider_for_doctor_image: "",
    provider_for_doctor_top_section: "",
    provider_for_doctor_main_section: "",
    provider_for_clinic_section: "",
    provider_for_clinic_section_image: "",
    provider_for_hospital_section_image: "",
    provider_for_hospital_section: "",
  }
  isloggedIn: boolean = false;

  ngOnInit() {
    this.getPageContent()
  }

  getPageContent() {
    this.cmsService.getContent().subscribe(
      (response: any) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].section_type == "provider_banner_content") {
            this.content.provider_banner_content = response.data[i].content;
          }
          if (response.data[i].section_type == "provider_banner_image") {
            this.content.provider_banner_image = response.data[i].content;
          }
          if (response.data[i].section_type == "provider_banner_app") {
            this.content.provider_banner_app = response.data[i].content;
          }
          if (response.data[i].section_type == "provider_users_detail") {
            this.content.provider_users_detail = response.data[i].content;
          }

          if (response.data[i].section_type == "provider_what_we_do") {
            this.content.provider_what_we_do = response.data[i].content;
          }

          if (response.data[i].section_type == "provider_for_doctor_image") {
            this.content.provider_for_doctor_image = response.data[i].content;
          }
          if (response.data[i].section_type == "provider_for_doctor_main_section") {
            this.content.provider_for_doctor_main_section = response.data[i].content;
          }
          if (response.data[i].section_type == "provider_for_doctor_top_section") {
            this.content.provider_for_doctor_top_section = response.data[i].content;
          }

          if (response.data[i].section_type == "provider_for_clinic_section") {
            this.content.provider_for_clinic_section = response.data[i].content;
          }
          if (response.data[i].section_type == "provider_for_clinic_section_image") {
            this.content.provider_for_clinic_section_image = response.data[i].content;
          }

          if (response.data[i].section_type == "provider_for_hospital_section_image") {
            this.content.provider_for_hospital_section_image = response.data[i].content;
          }
          if (response.data[i].section_type == "provider_for_hospital_section") {
            this.content.provider_for_hospital_section = response.data[i].content;
          }
        }
      }
    )
  }

}
