import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from "../../../services/common.service";
import { CmsService } from '../../../services/cms.service';

@Component({
  selector: 'frontend-footer',
  templateUrl: './frontend-footer.component.html',
  styleUrls: ['./frontend-footer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FrontendFooterComponent implements OnInit {
  content = {
    copyright_content: "",
  };
  footer_links = []
  constructor(
    private commonService: CommonService,
    private cmsService: CmsService,
  ) {
    this.getPageContent();
    this.geMenus();
  }

  ngOnInit() { }

  getPageContent() {
    this.cmsService.getContent("copyright_content").subscribe(
      (response: any) => {
        this.content.copyright_content = response.data.content;
      }
    )
  }

  medzigo = [];
  patient = [];
  doctor = [];
  more = [];
  social = [];
  clinic_hospital = [];
  geMenus() {
    this.cmsService.getMenus('', {menu_type:'Footer', pagination:'No'}).subscribe(
      (response: any) => {
        this.medzigo = response.data.filter(x => x.menu_category == 'Medzigo');
        this.patient = response.data.filter(x => x.menu_category == "For Patients");
        this.doctor = response.data.filter(x => x.menu_category == "For Doctors");
        this.more = response.data.filter(x => x.menu_category == "More");
        this.social = response.data.filter(x => x.menu_category == "Social");
        this.clinic_hospital = response.data.filter(x => x.menu_category == "Clinic Hospital");

        if (this.medzigo.length > 0) {
          this.medzigo = this.commonService.checkMenusUrlType(this.medzigo);
        }
        if (this.patient.length > 0) {
          this.patient = this.commonService.checkMenusUrlType(this.patient);
        }
        if (this.doctor.length > 0) {
          this.doctor = this.commonService.checkMenusUrlType(this.doctor);
        }
        if (this.more.length > 0) {
          this.more = this.commonService.checkMenusUrlType(this.more);
        }
        if (this.social.length > 0) {
          this.social = this.commonService.checkMenusUrlType(this.social);
        }
        if (this.clinic_hospital.length > 0) {
          this.clinic_hospital = this.commonService.checkMenusUrlType(this.clinic_hospital);
        }
      }
    )
  }

}
